import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPatreon } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { SocialButton } from "../components/SocialButton";
// import { MainButton } from "../components/MainButton";

export const Hero = () => {
    return <div className="flex h-screen bg-cover bg-tre justify-start items-center">
        <div className="p-3 md:p-16 m-auto" >
            <div className="text-white font-bold text-6xl text-left ">
                hey,
            </div>
            <div className="text-white text-2xl md:text-3xl mt-2 mb-8 text-left">
                <a href="https://www.youtube.com/watch?v=sNPnbI1arSE">my name is</a> <strong className="text-primary-lighter text-4xl">János Hrubos</strong>. 
            </div>
            <div className="text-white text-xl md:text-2xl mt-2 mb-6 text-left">
                i am a passionate <p className="italic">developer / guitarist / drummer</p> from Budapest, Hungary. 
            </div>
            {/* <MainButton text="Get in touch" type="primary" url="#contact"/> */}
            <div className="flex flex-wrap">
                <SocialButton icon={faGithub} url="https://github.com/janoshrubos"/>
                <SocialButton icon={faInstagram} url="https://instagram.com/janoshrubos/"/>
                <SocialButton icon={faPatreon} url="https://patreon.com/janoshrubos"/>
                <SocialButton icon={faTwitter} url="https://twitter.com/janoshrubos"/>
                <SocialButton icon={faTwitch} url="https://www.twitch.tv/janoshruboss"/>
                <SocialButton icon={faLinkedin} url="https://www.linkedin.com/in/janoshrubos/"/>
               
                {/* <Button text="More" type="secondary"/> */}
            </div>
        </div>
        {/* <div className="w-1/2 p-16"> */}
            {/* <img src="/tech.png"></img> */}
        {/* </div> */}
    </div>;
}