import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";

export const Languages = () => {
    return <div className="w-11/12 md:w-5/6 xl:w-1/2 m-auto">
    <div className="bg-white">
        <div className="text-left font-bold text-3xl flex items-center mb-8 mt-8">
                <div className="mr-4 bg-primary w-10 h-10 flex justify-center items-center rounded-3xl">
                    <FontAwesomeIcon icon={faUniversity} color="#FFF" size="xs"/>
                </div>
                <div className="text-primary">
                    LANGUAGES
                </div>
            </div>
            <div className="flex flex-col md:flex-row justify-between px-16 text-left border rounded-xl p-6 mb-8 shadow-lg">
                <div className="md:w-1/4 mb-2 md:mb-0 text-gray-400 font-bold text-md leading-5">
                    <div className="text-gray-700">Hungarian</div>
                    <div className="text-gray-400">Native or bilingual proficiency</div>
                </div>
                <div className="md:w-1/4 mb-2 md:mb-0 text-gray-400 font-bold text-md leading-5">
                    <div className="text-gray-700">English</div>
                        <div className="text-gray-400">Professional working proficiency</div>
                </div>
                <div className="md:w-1/4 mb-2 md:mb-0 text-gray-400 font-bold text-md leading-5">
                    <div className="text-gray-700">German</div>
                    <div className="text-gray-400">Elementary proficiency</div>
                </div>
            
            </div>
        </div>
    </div>;
}