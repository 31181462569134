import { JobComponent } from "../components/JobComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";

export const Education = () => {
    return <div className="w-11/12 md:w-5/6 xl:w-1/2 m-auto">
    <div className="bg-white">
        <div className="text-left font-bold text-3xl flex items-center mb-8 mt-8">
                <div className="mr-4 bg-primary w-10 h-10 flex justify-center items-center rounded-3xl">
                    <FontAwesomeIcon icon={faUniversity} color="#FFF" size="xs"/>
                </div>
                <div className="text-primary">
                    EDUCATION
                </div>
            </div>
            <JobComponent 
                yearFrom="2016" 
                yearTo="2019"
                position="Eötvös Loránd University"
                place="Bachelor of Computer Science"
                description={["Thesis - Band managing web app created with React.js and Spring Boot"]}
                />
        </div>
    </div>;
}