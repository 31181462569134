
export const JobComponent = ({yearFrom, yearTo, position, place, description}) => {
    return <div className="flex flex-col md:flex-row justify-start px-16 text-left border rounded-xl p-6 mb-8 shadow-lg">
        <div className="md:w-2/12 text-gray-400 font-bold text-md leading-5 text-center md:text-right">
            {yearFrom} - {yearTo}
        </div>
    
        <div className="md:w-1/12 flex m-4 md:m-0 md:flex-none justify-center">
            <div className="w-5 h-5 rounded-xl border-4 bg-purple-800"/>
        </div>

        <div className="md:w-9/12">
            <div className="font-bold text-black text-2xl leading-5 mb-1 tracking-wide">
                {position.toUpperCase()}
            </div>
            <div className="text-gray-500 text-md">
                {place}
            </div>
            <div className="text-gray-700 mt-2">
                <ul className="list-disc">
                {description.map(item => {
                    return <li className="my-2">{item}</li>
                })}
                </ul>
            </div>
        </div>
    
    </div>
}