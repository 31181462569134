import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faPatreon } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { SocialButton } from "./SocialButton";

export const FooterComponent = () => {
    return <div className="flex flex-col md:flex-row justify-center md:justify-between items-center w-full h-32 md:h-24 px-2 md:px-16 bg-gradient-to-r from-primary to-primary-light border-b shadow-lg">
    <div className="flex mb-2">
        <div className="flex items-center text-gray-800 font-bold md:ml-4 cursor-pointer">
            <a href="/" className="border-transparent border-b-4 flex">
                <div className="text-gray-200">Janos</div>
                <div className="text-white ml-1">Hrubos</div>
            </a>
        </div>
    </div>
    <div className="flex">
        <SocialButton icon={faGithub} url="https://github.com/janoshrubos"/>
        <SocialButton icon={faInstagram} url="https://instagram.com/janoshrubos/"/>
        <SocialButton icon={faPatreon} url="https://patreon.com/janoshrubos"/>
        <SocialButton icon={faTwitter} url="https://twitter.com/janoshrubos"/>
        <SocialButton icon={faTwitch} url="https://www.twitch.tv/janoshruboss"/>
        <SocialButton icon={faLinkedin} url="https://www.linkedin.com/in/janoshrubos/"/>
    </div>
</div>
}