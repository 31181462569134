export const TechnologiesComponent = ({icon, url}) => {
    const technologies = [
        {
            name: "React",
            skill: 90
        },
        {
            name: "React-Native",
            skill: 90
        },
        {
            name: "Redux",
            skill: 89
        },
        {
            name: "JavaScript",
            skill: 90
        },
        {
            name: "TypeScript",
            skill: 90
        },
        {
            name: "NativeScript",
            skill: 90
        },
        {
            name: "HTML",
            skill: 90
        },
        {
            name: "CSS",
            skill: 90
        },
        {
            name: "TailwindCSS",
            skill: 90
        },
        {
            name: "Java",
            skill: 80
        },
        {
            name: "SQL",
            skill: 80
        },
        {
            name: "PostgreSQL",
            skill: 80
        },
        {
            name: "Firebase",
            skill: 80
        },
        {
            name: "Capacitor",
            skill: 80
        },
        {
            name: "Ionic",
            skill: 80
        },
        {
            name: "Next.js",
            skill: 80
        },
        {
            name: "Docker",
            skill: 80
        },
        {
            name: "Redux",
            skill: 80
        },
        {
            name: "AWS",
            skill: 80
        },
        {
            name: "Next.js",
            skill: 80
        },
        {
            name: "Angular",
            skill: 80
        },
        {
            name: "Vue",
            skill: 80
        },
        {
            name: "CI/CD",
            skill: 80
        },
        {
            name: "Python",
            skill: 80
        },
        {
            name: "Git",
            skill: 80
        },
        {
            name: "Github",
            skill: 80
        },
        {
            name: "Gitlab",
            skill: 80
        },
        {
            name: "Spring Boot",
            skill: 80
        },
        {
            name: "WebSocket",
            skill: 80
        },
        {
            name: "CocoaPods",
            skill: 80
        },
        {
            name: "iOS",
            skill: 80
        },
        {
            name: "Android",
            skill: 80
        },
        {
            name: "Jira",
            skill: 80
        },
    ]
    return <div className="flex flex-wrap justify-center">
        {technologies.map(tech => {
            return <div className="py-1 px-2 m-1 text-gray-400 border-gray-400 rounded-md border">
                {tech.name}
            </div>
        })}
    </div>
}