import { HeaderButtonComponent } from "./HeaderButtonComponent";

export const HeaderComponent = () => {
    return <div className="flex justify-between w-full h-24 px-8 md:px-16 fixed bg-gray-50 border-b shadow-lg">
        <div className="flex">

            <div className="flex items-center text-gray-800 font-bold md:ml-4 cursor-pointer">
                <a href="/" className="border-transparent border-b-4 hover:border-primary flex">
                    <div>Janos</div>
                    <div className="text-primary ml-1">Hrubos</div>
                </a>
            </div>
        </div>
        <div className="flex place-content-center">
            <HeaderButtonComponent text="About" url="/"/>
            <HeaderButtonComponent text="Work" url="#work"/>
            {/* <HeaderButtonComponent text="Hobby" url="#hobby"/> */}
            {/* <HeaderButtonComponent text="Contact" url="#contact"/> */}
        </div>
    </div>
}