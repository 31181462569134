import './App.css';
import { HeaderComponent } from "./components/HeaderComponent";
import { Hero } from './pages/Hero';
import { Work } from './pages/Work';
import { Education } from './pages/Education';
import { FooterComponent } from './components/FooterComponent';
import { Languages } from './pages/Languages';

function App() {
  return (
    <div className="App">
      <HeaderComponent/>
      <Hero/>
      <Work/>
      <Education/>
      <Languages/>
      <FooterComponent/>
    </div>
  );
}

export default App;
