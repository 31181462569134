import { JobComponent } from "../components/JobComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { TechnologiesComponent } from "../components/TechnologiesComponent";

export const Work = () => {
    return <div id="work" className="w-11/12 md:w-5/6 xl:w-1/2 m-auto">
        <div className="bg-white">
            <div className="text-left font-bold text-3xl flex items-center mb-8 mt-8">
                <div className="mr-4 bg-primary w-10 h-10 flex justify-center items-center rounded-3xl">
                    <FontAwesomeIcon icon={faBriefcase} color="#FFF" size="xs"/>
                </div>
                <div className="text-primary">
                    WORK EXPERIENCE
                </div>
            </div>
            <JobComponent 
                yearFrom="2020" 
                yearTo=""
                position="Full Stack Developer"
                place="nStudio LLC"
                description={[
                    `Developing features and fixing bugs for NativeScript framework and the NativeScript CLI in TypeScript`,
                    `Working with React, Angular, Vue in templates`,
                    `Designing artworks for events`,
                    `Writing documentation`
                ]}
                />
            <JobComponent 
                yearFrom="2020" 
                yearTo="2021"
                position="Project Manager / Lead Developer"
                place="WRD Labs Zrt."
                description={[
                    `Planning what work needs to be done and when by building a comprehensive work plan`,
                    `Delegating tasks to the team members`,
                    `Making sure that the projects are running on time`,
                    `Motivating and supporting team members involved in the projects`,
                    `Managing and delivering expectations to clients`,
                    `Organizing meetings`,
                    `Supervising the work hours of the team members`
                ]}
                />
            <JobComponent 
                yearFrom="2019" 
                yearTo="2021"
                position="Full Stack Developer"
                place="WRD Labs Zrt."
                description={[
                    `Bringing concepts of websites and mobile apps to life`,
                    `Prioritizing user experience`,
                    `Database creation, integration and management`,
                    `API integration`,
                    `Creating test environments`,
                    `Automating software delivery process with CI/CD`,
                    `Next.js, PostreSQL, WebSocket, Docker, Redux, AWS`
                ]}
                />
        </div>
        <TechnologiesComponent/>
    </div>;
}